/** @format */

.slick-slide > div {
  padding: 0 10px; /* Adjust the padding value as per your spacing preference */
}
.login-container {
  overflow-x: hidden !important;
}
.display-error {
  color: rgb(255, 255, 255);
  text-shadow: rgb(230, 58, 72) -0.0625rem 0px, rgb(230, 58, 72) 0px 0.0625rem,
    rgb(230, 58, 72) 0.0625rem 0px, rgb(230, 58, 72) 0px -0.0625rem;
  font-size: 160px;
}
.giftcards .rts___tabs___container {
  justify-content: center;
}
:where(.css-dev-only-do-not-override-14wwjjs).ant-card .ant-card-body {
  padding: 3px !important;
  border-radius: 0 0 4px 4px;
  font-size: 12px;
  margin-top: 3px;
}

.backdrop {
  /* Backdrop styles */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  z-index: 999; /* Ensure the backdrop is above other elements */
}
.ant-dropdown-menu-item {
  padding: 11px 12px !important;

  min-width: 15vw !important;
  display: flex !important;
  justify-content: center !important;
}
.ant-collapse-content-box {
  padding-top: 20px !important;
}
.resend-text {
  text-decoration: underline;
  color: red;
  font-size: 15px;
  cursor: pointer;
  margin-left: 2px;
}
.main_nav .nav-item .nav-link {
  font-size: 14px !important;
}
.second-level-vertical-menu {
  min-width: 150px !important;
  display: block !important;
}
.brand_img img {
  height: 180px !important;
  width: 100% !important;
  object-fit: contain;
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  margin-top: 0.125rem;
  /* left: auto !important; */
  /* right: 0px !important; */
}
.v-menu[data-bs-popper] {
  top: 100%;
  margin-top: 0.125rem;
  left: auto !important;
  /* right: 0px !important; */
}

.dropdown-item.active,
.dropdown-item:active {
  color: inherit;
  text-decoration: none;
  background-color: #0d6efd;
}
.ant-drawer {
  z-index: 1500 !important;
}
.mobile-mega-menu-drawer .ant-drawer-body {
  padding: 0 !important;
}
.footer-2 a {
  color: white !important;
}
